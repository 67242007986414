import React, {useState} from 'react';
import PropTypes from 'prop-types';

const AppContext = React.createContext([{}, () => {}]);

const AppProvider = (props) => {
  const [appState, setAppState] = useState({
    ongoingSeason: 2023,
    firstSeason: 2018,
  });
  const [activeSeason, setActiveSeason] = useState(appState.ongoingSeason);
  const [activePage, setActivePage] = useState('home');
  return (
    <AppContext.Provider
      value={{
        appState,
        setAppState,
        activeSeason,
        setActiveSeason,
        activePage,
        setActivePage,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node,
};

export {AppContext, AppProvider};
