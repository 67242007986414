import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Table} from 'react-bootstrap';
import GamesRow from './GamesRow.js';
import {AppContext} from '../contexts/AppContext';
import Games2017to2018 from '../static-data/games17-18-sj66.json';
import Games2018to2019 from '../static-data/games18-19-sj66.json';
import Games2019to2020 from '../static-data/games19-20-sj66.json';
import Games2020to2021 from '../static-data/games20-21-sj66.json';
import Games2021to2022 from '../static-data/games21-22-sj66.json';
// import {useParams} from 'react-router-dom';

/**
 * Games schedule and results component
 * @param {*} props
 * @return {object} jsx
 */
const Games = ({season}) => {
  const [gamesData, setGamesData] = useState({games: []});
  const {appState, setActivePage} = useContext(AppContext);
  // const {seasonx} = useParams();
  // console.log(seasonx);

  useEffect(() => {
    setActivePage('games');
    const getCurrentSeasonData = async () => {
      const response = await fetch(
          process.env.PUBLIC_URL + '/season-data/games22-23-sj66.json'
      );
      const games = await response.json();
      // console.log('games', games);
      setGamesData(games);
    };
    const selectData = () => {
      switch (season) {
        case 2018:
          return Games2017to2018;
        case 2019:
          return Games2018to2019;
        case 2020:
          return Games2019to2020;
        case 2021:
          return Games2020to2021;
        case 2022:
          return Games2021to2022;
        default:
          console.error('season not set');
      }
    };
    if (season === appState.ongoingSeason) {
      getCurrentSeasonData();
    } else {
      setGamesData(selectData());
    }
  }, [season, appState]);

  const renderGameRows = gamesData.games.map((game) => {
    return (
      <GamesRow
        game={game}
        isCurrentSeason={season === appState.ongoingSeason}
        ongoingSeason={appState.ongoingSeason}
        key={game.UniqueID}
      />
    );
  });

  return (
    <Row>
      <Col>
        <article>
          <header>
            <h2>
              Divaripelit {season - 1}-{season}
            </h2>
          </header>
          {season === appState.ongoingSeason && (
            <p>
              Kuluvan kauden&nbsp;
              <a href="https://salibandy.fi/tulospalvelu/33/9218/10496/2022-2023/otteluohjelma">
                tulokset ja tulevat matsit.
              </a>
            </p>
          )}
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Aika</th>
                <th>Paikka</th>
                <th>Matsi</th>
                <th>Tulos</th>
              </tr>
            </thead>
            <tbody style={{fontSize: '.85em'}}>{renderGameRows}</tbody>
          </Table>
        </article>
      </Col>
    </Row>
  );
};

Games.propTypes = {
  season: PropTypes.number.isRequired,
};

export default Games;
