import React from 'react';
import {Row, Col} from 'react-bootstrap';


const Footer = (props) => {
  return (
    <footer>
      <Row>
        <Col>
          &copy; Salajengi 66 ry 2017 - {new Date().getFullYear()}
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
