import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders a row for Games schedule table
 * @param {*} props
 * @return {object} jsx
 */
const GamesRow = (props) => {
  const {game, isCurrentSeason, ongoingSeason} = props;

  /**
   * Convert time format from 00:00:00 to finnish 00.00
   *
   * Could be done with Moment lib too but this is
   * just an example how easy it can be without adding
   * external dependencies for small things
   * (e.g. like the date formatting below)
   *
   * @param {string} time Time string
   * @return {string}
   */
  const formatTime = (time) => {
    const hoursSecs = time.split(':');
    return hoursSecs[0] + '.' + hoursSecs[1];
  };

  /**
   * Convert YYYY-MM-DD to d.m.YYYY format
   * @param {string} date
   * @return {string}
   */
  const formatDate = (date) => {
    const yearMonthDay = date.split('-');
    // Using parseInt to get rid of leading zeros
    return parseInt(yearMonthDay[2]) + '.' +
      parseInt(yearMonthDay[1]) + '.' +
      yearMonthDay[0];
  };

  /**
   * Check the game result for a team and return
   * a highlight css class based on that
   *
   * @param {object} game Game object
   * @param {string} teamName Team to check
   * @return {string} name of Bootstrap css class
   */
  const getResultHighlightStyle = (game, teamName) => {
    let homeTeam;
    if (teamName === game.HomeTeamName) {
      homeTeam = true;
    } else if (teamName === game.AwayTeamName) {
      homeTeam = false;
    } else {
      return null;
    }
    const scores = game.Result.split('-');
    if (scores[0] === '') {
      // no result
      return '';
    }
    const homeScore = parseInt(scores[0], 10);
    const awayScore = parseInt(scores[1], 10);
    if (homeScore === awayScore) {
      // tie
      return 'table-warning';
    } else if (((homeScore > awayScore) && homeTeam) ||
               ((homeScore < awayScore) && !homeTeam)) {
      // winner
      return 'table-success';
    } else {
      // loser
      return 'table-danger';
    }
  };

  /**
   * Create a game spesific link to SSBL website (2020 -> tilastopalvelu)
   * TODO: figure out how to get the correct id for this: https://salibandy.fi/tulospalvelu/ottelut/222045/yhteenveto/
   * @return {string} weblink
   */
  const ssblGameLink = () => {
    // const homeTeam = game.HomeTeamName.toLowerCase();
    // const awayTeam = game.AwayTeamName.toLowerCase();
    // return `https://salibandy.fi/tulokset/#/ottelu/${homeTeam}-${awayTeam}/${game.UniqueID}/5936`;
    // Only Unique id for the game and the "season selector(?)" are needed
    // return `https://salibandy.fi/tulokset/#/ottelu/-/${game.UniqueID}/5936`;
    return `https://www.tilastopalvelu.fi/fb/gamesheet/?gid=${game.UniqueID}&season=${ongoingSeason}`;
  };

  return (
    <tr>
      <td>
        {/* <Moment date={game.GameDate} format="D.M.YYYY" /> */}
        {formatDate(game.GameDate)}
        <span style={{fontSize: '.9em'}}>
          <br />klo {formatTime(game.GameTime)}
        </span>
      </td>
      <td className="align-middle">{game.RinkName} {game.SubRink}</td>
      <td className="align-middle">
        {isCurrentSeason ? (
          <a href={ssblGameLink()}>{game.HomeTeamName} - {game.AwayTeamName}</a>
        ) : (
          <span>{game.HomeTeamName} - {game.AwayTeamName}</span>
        )}
      </td>
      <td className={getResultHighlightStyle(game, 'SJ 66') +
                     ' align-middle text-center'}>
        {game.Result}
      </td>
    </tr>
  );
};

GamesRow.propTypes = {
  game: PropTypes.object.isRequired,
  isCurrentSeason: PropTypes.bool.isRequired,
  ongoingSeason: PropTypes.number.isRequired,
};

export default GamesRow;
