import React from 'react';
import {Container} from 'react-bootstrap';
import Header from './components/Header';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import {AppProvider} from './contexts/AppContext';

const App = () => {
  return (
    <div className="App">
      <AppProvider>
        <Container>
          <Header />
          <Navigation />
          <Footer />
        </Container>
      </AppProvider>
    </div>
  );
};

export default App;
