import React from 'react';
import {Row, Col} from 'react-bootstrap';
import logo from '../assets/sj66logo-192.png';

const Header = (props) => {
  return (
    <Row>
      <Col>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>SJ66</h1>
        </header>
      </Col>
    </Row>
  );
};

export default Header;
