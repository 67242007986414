import React from 'react';
import PropTypes from 'prop-types';
import Standings from './Standings';
import {Row, Col} from 'react-bootstrap';

/**
 * Home component
 * The home page displaying some generic info and standings table
 * Holds some season related data too (TODO: move to db/json)
 * @param {*} props
 * @return {object}
 */
const Home = ({season}) => {
  return (
    <main>
      <Row>
        <Col md={5}>
          <section>
            <article>
              <header>
                <h2>Salajengi 66</h2>
              </header>
              {season === 2023 && (
                <p>
                  Tälläkin kaudella SJ66 painaa sählyä tavoitteet
                  taivaissa&nbsp;
                  <a href="https://salibandy.fi/tulospalvelu/33/9218/10496/2022-2023/otteluohjelma">
                    SSBL:n miesten kutosdivarissa Etelä-Suomen 2. lohkossa
                  </a>
                  . Lohkon kolme parasta joukkuetta nousee (jos haluaa)
                  seuraavalla kaudella vitoseen. Kattellaan.
                </p>
              )}
              {season === 2022 && (
                <p>
                  Taas mentiin&nbsp;
                  <a href="https://salibandy.fi/tulospalvelu/33/8099/8828/2021-2022/otteluohjelma">
                    SSBL:n miesten kutosdivarissa Etelä-Suomen 2. lohkossa
                  </a>
                  . Ei menty vitoseen.
                </p>
              )}
              {season === 2021 && (
                <p>
                  Pelattiin&nbsp;
                  <a href="https://salibandy.fi/tulospalvelu/33/6883/7676/2021/otteluohjelma/">
                    SSBL:n miesten kutosdivarissa Etelä-Suomen 3. lohkossa
                  </a>
                  . Pelaamalla piti vitoseen mennä, mutta kausi katkesi kolmen
                  pelin jälkeen.
                </p>
              )}
              {season === 2020 && (
                <p>
                  Pelattiin taas ihan nousujohteisesti(?) vuodenvaihteen
                  notkahduksen jälkeen kutosdivarin Etelä-Suomen 3. lohkossa,
                  mutta koronavirus pilasi ja keskeytti koko kauden.
                </p>
              )}
              {season === 2019 && (
                <p>
                  Kausi 2018-2019 pelattiin kutosta ES 5. lohkon keskikastissa.
                </p>
              )}
              {season === 2018 && (
                <p>
                  Neitsytkaudella 2017-2018 noustiin kauden viimeisen pelin
                  sensaatiomaisella tasurilla lohkovoittajaa vastaan nipin napin
                  lohkon kolmoseksi, mutta vain kaksi parasta nousi
                  vitosdivariin.
                </p>
              )}
            </article>
          </section>
        </Col>
        <Col md={7}>
          <section>
            <Standings season={season} />
          </section>
        </Col>
      </Row>
    </main>
  );
};

Home.propTypes = {
  season: PropTypes.number.isRequired,
};

export default Home;
