import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Table, Button, ButtonToolbar} from 'react-bootstrap';
import {AppContext} from '../contexts/AppContext';
import Stats2017to2018 from '../static-data/stats17-18.json';
import Stats2018to2019 from '../static-data/stats18-19.json';
import Stats2019to2020 from '../static-data/stats19-20.json';
import Stats2020to2021 from '../static-data/stats20-21.json';
import Stats2021to2022 from '../static-data/stats21-22.json';

/**
 * Player statistics
 *
 * @param {*} props
 * @return {object} <article />
 */
const Stats = (props) => {
  const {season: seasonSelected} = props;
  const {appState} = useContext(AppContext);
  const [showOnlyOwnTeam, setShowOnlyOwnTeam] = useState(true);
  const [stats, setStats] = useState([]);
  const ownTeamName = 'SJ 66';

  /**
   * Compare function for sorting players by points (total -> goals)
   *
   * @param {object} a
   * @param {object} b
   * @return {number} comparison result
   */
  const sortStats = (a, b) => {
    if (b.Points === a.Points) {
      return Number(b.Goals) - Number(a.Goals);
    }
    return Number(b.Points) - Number(a.Points);
  };

  useEffect(() => {
    const getCurrentSeasonData = async () => {
      const response = await fetch(
          process.env.PUBLIC_URL + '/season-data/stats22-23.json'
      );
      const stats = await response.json();
      setStats(stats.players.sort(sortStats));
    };
    const selectSeasonData = () => {
      switch (seasonSelected) {
        case 2018:
          return Stats2017to2018.players;
        case 2019:
          return Stats2018to2019.players;
        case 2020:
          return Stats2019to2020.players;
        case 2021:
          return Stats2020to2021.players;
        case 2022:
          return Stats2021to2022.players;
        default:
          console.error('season not set');
      }
    };
    if (seasonSelected === appState.ongoingSeason) {
      getCurrentSeasonData();
    } else {
      setStats(selectSeasonData().sort(sortStats));
    }
    // console.log('stats', stats);
  }, [appState, seasonSelected]);

  /**
   * Render table rows, filter only own team stats when selected
   */
  let selectedStats;
  if (showOnlyOwnTeam) {
    selectedStats = stats.filter((stat) => {
      return stat.TeamName === ownTeamName;
    });
  } else {
    selectedStats = stats;
  }
  // console.log('selectedStats', selectedStats);
  const statRows = selectedStats.map((stat, index) => {
    let className = '';
    if (!showOnlyOwnTeam && stat.TeamName === ownTeamName) {
      className = 'highlight';
    }
    return (
      <tr key={stat.PlayerID} className={className}>
        <td className="align-middle">{index + 1}</td>
        <td>{stat.PlayerName}</td>
        {!showOnlyOwnTeam && <td className="align-middle">{stat.TeamName}</td>}
        <td className="align-middle">{stat.Games}</td>
        <td className="align-middle">{stat.Goals}</td>
        <td className="align-middle">{stat.Assists}</td>
        <td className="align-middle">
          {parseInt(stat.Goals) + parseInt(stat.Assists)}
        </td>
        <td className="align-middle">{stat.PenaltyMin}</td>
      </tr>
    );
  });

  return (
    <article>
      <Row>
        <Col>
          <header>
            <h2>Statsit</h2>
          </header>
          {showOnlyOwnTeam && (
            <p>
              Jengin pistepörssi kaudella&nbsp;
              {seasonSelected - 1}-{seasonSelected}.
            </p>
          )}
          {!showOnlyOwnTeam && (
            <p>
              Koko lohkon pistepörssi kaudella&nbsp;
              {seasonSelected - 1}-{seasonSelected}.
            </p>
          )}
          <ButtonToolbar>
            <Button
              onClick={() => {
                setShowOnlyOwnTeam(!showOnlyOwnTeam);
              }}
            >
              Näytä {showOnlyOwnTeam ? 'koko lohko' : 'vain SJ66'}
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Pelaaja</th>
                {!showOnlyOwnTeam && <th>Joukkue</th>}
                <th>Pelit</th>
                <th>Maalit</th>
                <th>Syötöt</th>
                <th>Pojot</th>
                <th>Jäähyllä</th>
              </tr>
            </thead>
            <tbody>{statRows}</tbody>
          </Table>
        </Col>
      </Row>
    </article>
  );
};

Stats.propTypes = {
  season: PropTypes.number.isRequired,
};

export default Stats;
