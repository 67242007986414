import React, {useContext} from 'react';
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';
import {Row, Col} from 'react-bootstrap';
import Home from './Home';
import Games from './Games';
import Stats from './Stats';
import SeasonSelector from './SeasonSelector';
import {AppContext} from '../contexts/AppContext';

/**
 * Navigation component
 * - Router for switching between pages
 * - Dropdown menu for selecting season (state)
 * @return {object} jsx
 */
const Navigation = () => {
  // Season state uses the latter year of the season (e.g. 2019-2020 => 2020)
  const {activeSeason} = useContext(AppContext);

  return (
    <Router>
      <nav>
        <Row>
          <Col>
            <Link to="/">Koti</Link>
          </Col>
          <Col>
            {/* <Link to={'/games/' + activeSeason}>Matsit</Link> */}
            <Link to="/matsit">Matsit</Link>
          </Col>
          <Col>
            <Link to="/statsit">Statsit</Link>
          </Col>
        </Row>
      </nav>
      <Row>
        <Col style={{marginBottom: '.3em'}}>
          <SeasonSelector />
        </Col>
      </Row>
      {/* <Navigate from="/" to="/home" /> */}
      <Routes>
        <Route exact path="/" element={<Home season={activeSeason} />} />
        {/* <Navigate exact from="/games" to={'/games/' + seasonSelected} /> */}
        <Route
          // path="/games/:seasonx"
          path="/matsit"
          element={<Games season={activeSeason} />}
        />
        <Route path="/statsit" element={<Stats season={activeSeason} />} />
      </Routes>
    </Router>
  );
};

export default Navigation;
