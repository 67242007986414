import React, {useContext} from 'react';
import {DropdownButton, ButtonToolbar, Dropdown} from 'react-bootstrap';
import {AppContext} from '../contexts/AppContext';
// import {useNavigate} from 'react-router-dom';

const SeasonSelector = () => {
  const {appState, activeSeason, setActiveSeason} = useContext(AppContext);
  const seasonOptions = [];
  // const navigate = useNavigate();
  for (let i = appState.ongoingSeason; i >= appState.firstSeason; i--) {
    seasonOptions.push(i);
  }
  return (
    <ButtonToolbar className="float-right">
      <DropdownButton
        size="sm"
        variant="secondary"
        title={'Kausi ' + (activeSeason - 1) + '-' + activeSeason}
      >
        {seasonOptions.map((seasonOption, index) => {
          return (
            <Dropdown.Item
              key={index}
              eventKey={index + 1}
              onClick={() => {
                setActiveSeason(seasonOption);
                // navigate(`/${activePage}/${seasonSelection}`);
              }}
              active={activeSeason === seasonOption}
            >
              {seasonOption - 1 + '-' + seasonOption}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
    </ButtonToolbar>
  );
};

export default SeasonSelector;
