import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Table} from 'react-bootstrap';
import sj66logo100 from '../assets/sj66logo-100.png';
import Standings2017to2018 from '../static-data/standings17-18.json';
import Standings2018to2019 from '../static-data/standings18-19.json';
import Standings2019to2020 from '../static-data/standings19-20.json';
import Standings2020to2021 from '../static-data/standings20-21.json';
import Standings2021to2022 from '../static-data/standings21-22.json';
import {AppContext} from '../contexts/AppContext';

/**
 * Standings table component
 * TODO: refactor qualify limit functionality
 * By default the best 3 teams are qualified to upper division
 * @param {*} props
 * @return {object} jsx
 */
const Standings = (props) => {
  const {season: seasonSelected} = props;
  const [standingsData, setStandingsData] = useState({
    qualifyLimit: 3,
    teams: [],
  });
  const {appState, setActivePage} = useContext(AppContext);

  useEffect(() => {
    setActivePage('home');
    const getCurrentSeasonData = async () => {
      const response = await fetch(process.env.PUBLIC_URL +
                                   '/season-data/standings22-23.json');
      const standings = await response.json();
      // console.log('standings', standings);
      standings.qualifyLimit = 3;
      setStandingsData(standings);
    };
    const selectData = () => {
      switch (seasonSelected) {
        case 2018:
          Standings2017to2018.qualifyLimit = 2;
          return Standings2017to2018;
        case 2019:
          Standings2018to2019.qualifyLimit = 3;
          return Standings2018to2019;
        case 2020:
          Standings2019to2020.qualifyLimit = 3;
          return Standings2019to2020;
        case 2021:
          Standings2020to2021.qualifyLimit = 3;
          return Standings2020to2021;
        case 2022:
          Standings2021to2022.qualifyLimit = 3;
          return Standings2021to2022;
        case 2023:
          // This is the current (ongoing) season
          break;
        default:
          console.error('season not set');
      }
    };

    if (seasonSelected === appState.ongoingSeason) {
      getCurrentSeasonData();
    } else {
      setStandingsData(selectData());
    }
  }, [seasonSelected, appState]);

  const qualifyBorderStyle = {borderTop: 'solid green 2px'};
  const standingsRows = standingsData.teams.map((team, index) => {
    return (
      <tr key={team.UniqueID}
        style={index === standingsData.qualifyLimit ? qualifyBorderStyle : {}}
      >
        <td>{index + 1}</td>
        <td>
          {team.TeamName !== 'SJ 66' && team.TeamName}
          {team.TeamName === 'SJ 66' &&
            <img src={sj66logo100}
              style={{padding: ' 0 .5em 0 .5em'}}
              height="40" alt="SJ66 logo" />
          }
        </td>
        <td className="align-middle text-center">{team.Games}</td>
        <td className="align-middle text-center">{team.Points}</td>
        <td className="align-middle text-center">{team.Wins}</td>
        <td className="align-middle text-center">{team.Ties}</td>
        <td className="align-middle text-center">{team.Looses}</td>
        <td className="align-middle text-center">{team.GoalDiff}</td>
      </tr>
    );
  });

  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>#</th>
          <th>Joukkue</th>
          <th>Pelit</th>
          <th>Pisteet</th>
          <th>Voitot</th>
          <th>Tasurit</th>
          <th>Häviöt</th>
          <th>ME</th>
        </tr>
      </thead>
      <tbody>
        {standingsRows}
      </tbody>
    </Table>
  );
};

Standings.propTypes = {
  season: PropTypes.number.isRequired,
};

export default Standings;
